import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
    View,
    ScrollView,
    Text,
    StyleSheet,
    FlatList,
    Image,
    TouchableOpacity,
    Platform,
    Button,
    ActivityIndicator,
    Linking,
    SafeAreaView
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import {NavigationContainer, DarkTheme} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
    createDrawerNavigator,
    DrawerContentScrollView,
    DrawerItemList,
    DrawerItem,
} from '@react-navigation/drawer';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import NewTweet from './screens/NewTweet';
import TweetScreen from './screens/TweetScreen';
import ProfileScreen from './screens/ProfileScreen';
import { AuthContext, AuthProvider } from './context/AuthProvider';
import LoginScreen from './screens/Auth/LoginScreen';
import RegisterScreen from './screens/Auth/RegisterScreen';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axiosConfig from "./helpers/axiosConfig";
import RenderHtml from 'react-native-render-html';
import WebView from 'react-native-webview';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
// import './App.css';
import Slideshow from "react-native-slideshow-improved";
import AppStyle from './AppStyle.js';

import ReactDOM from "react-dom";
import { Gallery, GalleryImage } from "react-gesture-gallery";


const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();

const renderers = {
    iframe: IframeRenderer
};

const customHTMLElementModels = {
    iframe: iframeModel
};

// const config = {
//     screens: {
//         'Access Services': {
//             path: 'test-path',
//         },
//     },
// };
const linking = {
    prefixes: ['http://localhost:19006', 'localhost:19006'],
};


const MyTheme = {
    ...DarkTheme,
    colors: {
        primary: '#fff',
        background: 'transparent',
        card: '#0A2A51',
        text: '#fff',
        border: '#0C2D53'
    },
};



const HomeStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: true, headerBackTitleVisible: false }}
    >
      <Stack.Screen
        name="Tab"
        component={TabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="New Tweet"
        component={NewTweet}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="Tweet Screen"
        component={TweetScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="Profile Screen"
        component={ProfileScreen}
        options={{ title: '' }}
      />
    </Stack.Navigator>
  );
};



const AuthStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: true, headerBackTitleVisible: false }}
    >
      <Stack.Screen
        name="Login Screen"
        component={LoginScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Register Screen"
        component={RegisterScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
      }}
    >
      <Tab.Screen
        name="Home1"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="call-outline" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Search"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="people-outline" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Notifications"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="mail-outline" size={size} color={color} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    // check if user is logged in or not.
    // Check SecureStore for the user object/token

    // SecureStore.getItemAsync('user')
      AsyncStorage.getItem('user')
      .then(userString => {
        if (userString) {
          setUser(JSON.parse(userString));
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });

    // console.log('here4');
  }, []);

  if (isLoading) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="large" color="gray" />
      </View>
    );
  }

  console.log(user);

    function ContentScreen(props, { navigation }) {
        const [subcontent, setSubcontent] = useState(props.route.params.newSubcontent)
        // console.log(user);
        console.log('PROPS:');
        console.log(props);
        console.log(subcontent);

        var source = {
            html: props.route.params.content.content.replaceAll("/core/files/wellspringeap/", "https://www.wellspringeap.org/core/files/wellspringeap/")
        };

        // setSubcontent(props.route.params);

        // console.log(props.route.params);

        return (
            <View style={ styles.container }>
                <View style={styles.contentWrap}>
                    {subcontent === 'initial' && (
                        <>
                            <RenderHtml
                                source={{ html: props.route.params.content.content.replaceAll("/core/files/wellspringeap/", "https://www.wellspringeap.org/core/files/wellspringeap/") }}
                            />
                            <View>
                                {props.route.params.content.resources.map(d =>
                                    <>
                                        {d.url.indexOf('http') == -1 && d.content != '' && (
                                            <View key={d.id} onClick={() => setSubcontent(d.id)}>
                                                <Text style={ styles.subnavTitle }>{d.title}</Text>
                                                <View style={ styles.subnavDescri }>
                                                    <RenderHtml
                                                        source={{ html: d.description }}
                                                    />
                                                </View>
                                            </View>
                                        )}
                                        {d.url.indexOf('http') != -1 && (
                                            <View key={d.id} onClick={() => {
                                                Linking.openURL(d.url);
                                            }}>
                                                <Text style={ styles.subnavTitle }>{d.title}</Text>
                                                <View style={ styles.subnavDescri }>
                                                    <RenderHtml
                                                        source={{ html: d.description }}
                                                    />
                                                </View>
                                            </View>
                                        )}
                                    </>
                                )}
                            </View>
                        </>
                    )}
                </View>
                <View style={styles.contentWrap}>
                    {subcontent !== 'initial' && (
                        <>
                            {props.route.params.content.resources.map(d =>
                                <View key={d.id}>
                                    {d.id === subcontent && (
                                        <>
                                            <TouchableOpacity onPress={() => setSubcontent('initial')}>
                                                <Text style={ styles.backButton }>Back</Text>
                                            </TouchableOpacity>
                                            <Text style={ styles.pageTitle }>{d.title}</Text>
                                            <View style={ styles.pageContent }>
                                                <RenderHtml
                                                    source={{ html: d.content.replaceAll("/core/files/wellspringeap/", "https://www.wellspringeap.org/core/files/wellspringeap/") }}
                                                />
                                            </View>
                                            <View>
                                                {Array.isArray(d.resources)
                                                    ? d.resources.map(r =>
                                                        <>
                                                            {r.url.indexOf('http') == -1 && (
                                                                <View key={r.id} onClick={() => setSubcontent(r.id)}>
                                                                    <Text style={ styles.subnavTitle }>{d.title}</Text>
                                                                    <View style={ styles.subnavDescri }>
                                                                        <RenderHtml
                                                                            source={{ html: r.description }}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            )}
                                                            {r.url.indexOf('http') != -1 && (
                                                                <View key={r.id} onClick={() => {
                                                                    Linking.openURL(r.url);
                                                                }}>
                                                                    <Text style={ styles.subnavTitle }>{r.title}</Text>
                                                                    <View style={styles.subnavDescri}>
                                                                        <RenderHtml
                                                                            source={{ html: r.description }}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            )}
                                                        </>
                                                    )
                                                    : null}
                                            </View>
                                        </>
                                    )}
                                    {Array.isArray(d.resources)
                                        ? d.resources.map(r =>
                                            <View key={r.id}>
                                                {r.id === subcontent && (

                                                    <>
                                                        <button onClick={() => setSubcontent('initial')} style={ styles.backButton }>Back</button>
                                                        <Text style={ styles.pageTitle }>{r.title}</Text>
                                                        {
                                                            r.content.match(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g) ?
                                                                r.content.match(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g).map(ifr =>
                                                                    <>
                                                                        <View dangerouslySetInnerHTML={{__html: ifr}}></View>
                                                                    </>
                                                                )
                                                                : null
                                                        }
                                                        <RenderHtml
                                                            source={{ html: r.content.replaceAll("/core/files/wellspringeap/", "https://www.wellspringeap.org/core/files/wellspringeap/") }}
                                                        />
                                                    </>
                                                )}
                                            </View>)
                                        : null}
                                </View>
                            )}
                        </>

                    )}
                </View>
            </View>
        );
    }

    const HomeScreen = ({ route, navigation }) => {
        const [data, setData] = useState(null);
        const [scrollPosition, setScrollPosition] = useState(0);
        const scrollViewRef = useRef(null);
        const [isLoading, setIsLoading] = useState(true);
        const [isRefreshing, setIsRefreshing] = useState(false);
        const [page, setPage] = useState(1);
        const [isAtEndOfScrolling, setIsAtEndOfScrolling] = useState(false);
        const flatListRef = useRef(null);
        const { user } = useContext(AuthContext);
        const [index, setIndex] = useState(0);

        useEffect(() => {
            setData(user);
            setIsAtEndOfScrolling(true);
            setIsLoading(false);
            setIsRefreshing(false);
        }, []);

        const slides_length = user.temp_content.slides.length - 1;

        useEffect(() => {
            const timer = setInterval(() => {
                if (index === slides_length) {
                    setIndex(0);
                } else {
                    setIndex((prev) => prev + 1);
                }
            }, 3000);
            return () => clearInterval(timer);
        }, [index]);

        useEffect(() => {
            if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({ y: scrollPosition });
            }
        }, [index]);

        const handlePress = (url) => {
            Linking.openURL(url);
        };

        return (
                <View style={{ backgroundColor: 'transparent', width: '100%' }}>
                    <ScrollView horizontal={false} nestedScrollEnabled={false}>
                        {isLoading ? (
                            <ActivityIndicator style={{ marginTop: 8 }} size="large" color="gray" />
                        ) : (
                            <View>
                                {data.temp_content.slides.length > 1 ? (
                                    <Gallery
                                        style={{
                                            backgroundColor: '#eee',
                                            height: 500,
                                            width: '100%',
                                        }}
                                        index={index}
                                        onRequestChange={setIndex}
                                    >
                                        {data.temp_content.slides.map((image, idx) => (
                                            <View key={idx} style={styles.homepageGallery}>
                                                <Image style={styles.GalleryImage} source={{ uri: image.url }} />
                                                <Text style={styles.galleryTitle}>{image.title}</Text>
                                                <Text style={styles.galleryDescri}>{image.description}</Text>
                                                <TouchableOpacity onPress={() => handlePress(image.link)} style={styles.homepageGalleryLink}>
                                                    <Text style={styles.homepageGalleryLinkText}>Read More</Text>
                                                </TouchableOpacity>
                                            </View>
                                        ))}
                                    </Gallery>
                                ) : (
                                    <>
                                        {data.temp_content.slides.map((image, idx) => (
                                            <View key={idx} style={styles.homepageGallery}>
                                                <Image style={styles.homepageGalleryImage} source={{ uri: image.url }} />
                                                <Text style={styles.galleryTitle}>{image.title}</Text>
                                                <Text style={styles.galleryDescri}>{image.description}</Text>
                                                <TouchableOpacity onPress={() => handlePress(image.link)} style={styles.homepageGalleryLink}>
                                                    <Text style={styles.homepageGalleryLinkText}>Read More</Text>
                                                </TouchableOpacity>
                                            </View>
                                        ))}
                                    </>
                                )}
                            </View>
                        )}
                    </ScrollView>
                    <FlatList
                        data={user.temp_content.tools}
                        keyExtractor={(item) => item.id.toString()}
                        numColumns={2}
                        contentContainerStyle={styles.iconsWrap}
                        renderItem={({ item }) => (
                            <View style={styles.homepageIcons}>
                                {item.link.url.indexOf('http') !== -1 ? (
                                    <TouchableOpacity onPress={() => handlePress(item.link.url)} key={item.title}>
                                        <View style={styles.subnavDescri}>
                                            <Image style={styles.gridItemImage} source={{ uri: item.icon }} />
                                        </View>
                                        <Text style={styles.flatListTitle}>{item.title}</Text>
                                    </TouchableOpacity>
                                ) : (
                                    <TouchableOpacity onPress={() => handlePress(item.link.url)} key={item.title}>
                                        <View style={styles.subnavDescri}>
                                            <Image style={styles.gridItemImage} source={{ uri: item.icon }} />
                                        </View>
                                        <Text style={styles.flatListTitle}>{item.title}</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        )}
                    />
                </View>
        );
    };


    function CustomDrawerContent(props, { navigation }) {
        return (
            <DrawerContentScrollView {...props} activeBackgroundColor='rgba(0, 0, 0, .04)' style={styles.drawerStyles}>
                <DrawerItem
                    label="✖"
                    onPress={() => props.navigation.closeDrawer()}
                    labelStyle={{ color: "#fff", margin: '0 !important', fontSize:'21px', overflow: 'visible', lineHeight: '1', position: 'relative', top: '-9px', left: '-1px'}}
                    style={{position: 'relative', left: '10px', width: '34px', height: '34px', border: '1px solid #fff', borderRadius: '100%', color: '#ffffff', marginTop: '30px', marginBottom: '60px !important', lineHeight: '1', margin: '0'}}
                /><br/>
                <DrawerItemList {...props} />

                {user.temp_navigation.map((d, index) => {
                    if (d.text !== 'Log Out' && d.link.address.indexOf('http') !== -1) {
                        return (
                            <Text
                                key={index}
                                onPress={() => Linking.openURL(d.link.address)}
                                style={styles.navLink}
                            >
                                {d.text}
                            </Text>
                        );
                    }
                })}
                <DrawerItem label="Log Out" onPress={logout} />
            </DrawerContentScrollView>
        );
    }



    const phoneLinkPress = () => {
        Linking.openURL('tel:5093624020');
    };

    const emailLinkPress = () => {
        Linking.openURL('mailto:alexandr.semco@ziplineinteractive.com');
    };

  return (
    <>
        <NavigationContainer theme={MyTheme}>
            <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps="handled">
                <View style={styles.topBar}>
                    <View style={styles.clientLogo}>
                        <Image
                            alt=""
                            draggable="false"
                            source={{
                                uri:
                                    'https://www.wellspringeap.org/core/files/wellspringeap/clients/' +
                                    (user?.avatar || ''),
                            }}
                            style={{ width: 160, height: 60 }}
                            resizeMode="cover"
                        />
                    </View>
                    <View style={styles.wellspringLogo}>
                        <Image
                            source={{
                                uri:
                                    'https://www.wellspringeap.org/core/templates/wellspringeap/web/images/logos/WS_Logo_Horiz_1EAP_RGB_Wht.png',
                            }}
                            style={{ width: 100, height: 50 }}
                        />
                    </View>
                </View>
                {user ? (
                    <Drawer.Navigator
                        drawerContent={(props) => <CustomDrawerContent {...props} />}
                        initialRouteName="Home"
                        screenOptions={{
                            headerShown: true,
                            drawerPosition: 'right',
                            headerStyle: {
                                backgroundColor: 'transparent',
                                height: '90px',
                            },
                            headerTintColor: '#0A2A51',
                            headerTitleStyle: {
                                display: 'none',
                            },
                            overlayColor: 'transparent',
                            sceneContainerStyle: {},
                            drawerActiveTintColor: '#fff',
                            drawerTintColor: '#fff',
                            drawerLabelStyle: {
                                color: '#fff',
                                fontSize: '16px',
                            },
                        }}
                    >
                        {user.temp_navigation.map((d) => {
                            if (d.text === 'Home') {
                                return (
                                    <Drawer.Screen
                                        key={d.link.address}
                                        name={d.text}
                                        initialParams={{ content: d.temp_content }}
                                        options={{ unmountOnBlur: true }}
                                        component={HomeScreen}
                                    />
                                );
                            }
                            if (d.text !== 'Log Out') {
                                if (d.link.address.indexOf('http') === -1) {
                                    return (
                                        <Drawer.Screen
                                            key={d.link.address}
                                            name={d.text}
                                            initialParams={{
                                                content: d.temp_content,
                                                link: d.link,
                                                newSubcontent: 'initial',
                                            }}
                                            options={{ unmountOnBlur: true }}
                                            component={ContentScreen}
                                        />
                                    );
                                }
                            }
                        })}
                    </Drawer.Navigator>
                ) : (
                    <AuthStackNavigator />
                )}
            </ScrollView>
        </NavigationContainer>


        <View style={ styles.buttonNavWrap }>
            <TouchableOpacity style={ styles.phoneLinkWrap }>
                <Text onPress={ phoneLinkPress } style={ styles.buttonNavText }><Image source={require('./assets/phone-icon.png')} style={styles.phoneIcon}/>phone</Text>
            </TouchableOpacity>

            <TouchableOpacity style={ styles.emailLinkWrap }>
                <Text onPress={ emailLinkPress } style={ styles.buttonNavText }><Image source={require('./assets/referral-icon-new.png')} style={styles.referralIcon}/>request a referral</Text>
            </TouchableOpacity>
        </View>
    </>
  );
}


const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        flex: 1,
        overflowX: 'hidden',
    },
    homepageGallery: {
        backgroundColor: '#eee',
        paddingBottom: 30
    },
    homepageGalleryLink: {
        margin: 0,
        marginTop: 20,
        backgroundColor: '#2d4369',
        color: '#fff',
        display: 'inline-block',
        paddingVertical: 8,
        paddingHorizontal: 20,
        width: '140px',
        textAlign: 'center',
        marginLeft: '20px',
        textDecorationLine: 'none',
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: 15,
        lineHeight: 1
    },
    homepageGalleryLinkText: {
        color: '#fff',
        fontWeight: '600',
        fontSize: 15
    },
    homepageGalleryImage: {
        width: '100%'
    },
    GalleryImage: {
        height: '220px',
        width: '100%',
        resizeMode: 'cover'
    },
    galleryDescri: {
        fontSize: 16,
        fontWeight: '600',
        padding: 0,
        paddingHorizontal: 20
    },
    galleryTitle: {
        color: '#2d4369',
        padding: 0,
        fontSize: 28,
        fontWeight: 700,
        lineHeight: '1',
        paddingVertical: 20,
        textTransform: 'uppercase',
        paddingHorizontal: 20
    },
    contentWrap: {
        width: '100%',
        padding: 25,
        boxSizing: 'border-box',
    },
    wellspringLogo: {
        position: 'absolute',
        right: 5,
        top: 5
    },
    clientLogo: {
        position: 'absolute',
        left: 50,
        top: 13,
    },
    iconsWrap: {
        flex: 1,
        width: '100%',
        display: 'block',
        marginTop: 50,
        marginBottom: 80,
    },
    subnavDescri: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridItemImage: {
        width: '100%',
        height: 52,
        marginBottom: 15,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    subnavTitle: {
        fontWeight: 'bold',
        fontFamily: 'Segoe UI',
        fontSize: 18,
        margin: 0,
        lineHeight: '1.2',
        display: 'block',
        color: 'rgb(45, 67, 105)',
    },
    flatListTitle: {
        fontWeight: 'bold',
        fontFamily: 'Segoe UI',
        fontSize: 18,
        margin: 0,
        lineHeight: '1.2',
        display: 'block',
        textAlign: 'center',
        color: 'rgb(45, 67, 105)',
    },
    topBar: {
        textAlign: 'right',
        width: '100%',
        position: 'absolute',
        right: 0,
        boxSizing: 'border-box',
        paddingTop: 14,
        paddingRight: 30,
        paddingLeft: 30,
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconImage: {
        resizeMode: 'contain',
        marginVertical: 0,
    },
    homepageIcons: {
        width: '50%',
        flex: 1,
        flexShrink: 1,
        flexGrow: 1,
        paddingVertical: 10,
        paddingHorizontal: 0,
        marginHorizontal: 26,
        marginTop: 15,
        marginBottom: 15,
    },
    buttonNavText: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center'
    },
    phoneLinkWrap: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        lineHeight: '1.2',
        width: '40%',
    },
    emailLinkWrap: {
        borderLeftWidth: 1,
        borderLeftColor: '#0A2A51',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        lineHeight: '1.2',
        width: '60%',
    },
    buttonNavWrap: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#eee',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10,
        paddingHorizontal: 30,
        zIndex: 99,
    },
    phoneIcon: {
        width: 26,
        height: 26,
        margin: '0px auto',
        marginTop: '5px',
        marginBottom: '4px',
        position: 'relative',
        marginRight: '10px'
    },
    referralIcon: {
        width: 27,
        height: 31,
        margin: '0px auto',
        marginBottom: '4px',
        position: 'relative',
        marginRight: '10px',
        marginLeft: '10px'
    },
    emailIcon: {
        width: 36,
        height: 23,
        margin: '0px auto',
        marginTop: '8px',
        marginBottom: '4px',
        position: 'relative'
    },
    drawerStyles: {
        display: 'inline-block',
        position: 'absolute',
    },
    titleStyle: {
        fontSize: '155px'
    },
    navLink: {
        color: '#fff',
        fontSize: 16,
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        paddingLeft: 18,
        width: '100%',
        paddingTop: 16,
        paddingBottom: 16,
    },
    backButton: {
        position: 'absolute',
        right: 0,
        top:'-40px',
        padding: 7,
        paddingHorizontal: 20,
        borderWidth: 0,
        backgroundColor: '#000',
        fontWeight: 'bold',
        color: '#fff',
    },
    pageTitle: {
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        display: 'block',
        width: '100%',
        fontSize: 25,
        marginTop: 0,
        marginBottom: 20,
    },
});