import React, { useContext, useState, useCallback  } from 'react';
import {
  View,
  Text,
  TextInput,
  Button,
  ActivityIndicator,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { AuthContext } from '../../context/AuthProvider';
import AppStyle from '../../AppStyle.js';

const supportedURL = "http://eap.solutions/";

const OpenURLButton = ({ url, children }) => {
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, [url]);

  return <Button width= "50%" bgcolor = "#ff5c5c" title={children} onPress={handlePress} />;
};

export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, isLoading } = useContext(AuthContext);

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 0, width: '100%'}}>
        <View style={{ alignItems: 'center' }}>
          <Image
            style={styles.logo}
            source={require('../../assets/WS_Logo_Horiz.png')}
            resizeMode="contain"
            style={{width: 200, height: 90}}
            ></Image>
        </View>
        <div>
        <View style={styles.loginWrap}>
          {error && <Text style={{ color: 'red' }}>{error}</Text>}<br/>
            <Text style={{ marginTop: 0, marginBottom: 10, fontSize: '28px', color: '#fff', fontWeight: 'bold' }}>Access your EAP Benefits</Text>
            <Text style={{ lineHeight: '1.4', color: '#fff', fontSize: 16 }}>Login to your EAP benefits portal by typing in your username below. If you don't know your username, contact your HR department.</Text>
            <TextInput
              style={[styles.inputBox, styles.mt4]}
              onChangeText={setEmail}
              value={email}
              placeholder="Username"
              placeholderTextColor="gray"
              textContentType="none"
              autoCapitalize="none"
            />
          {/*<TextInput*/}
          {/*  style={[styles.inputBox, styles.mt4]}*/}
          {/*  onChangeText={setPassword}*/}
          {/*  value={password}*/}
          {/*  placeholder="Password"*/}
          {/*  placeholderTextColor="gray"*/}
          {/*  autoCapitalize="none"*/}
          {/*  secureTextEntry={true}*/}
          {/*/>*/}
        <TouchableOpacity
          onPress={() => login(email, password)}
          style={[styles.loginButton, styles.mt5]}
        >
          {isLoading && (
            <ActivityIndicator
              style={{ marginRight: 18 }}
              size="small"
              color="white"
            />
          )}
          <Text style={styles.loginButtonText}>Login</Text>
        </TouchableOpacity>
        </View>
      </div>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <div>
          {/*<OpenURLButton url={supportedURL}>I am interested in an EAP for my company or my client</OpenURLButton>*/}
            <Text style={styles.loginLink}
                  onPress={() => Linking.openURL('http://eap.solutions/')}>
              I am interested in an EAP for my company or my client
            </Text>
          </div>
          {/*<Text style={styles.registerText}>I am interested in an EAP for my company or my client</Text>*/}
          {/*<TouchableOpacity*/}
          {/*  onPress={() => navigation.navigate('Register Screen')}*/}
          {/*>*/}
          {/*</TouchableOpacity>*/}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  logo: {
    width: 300,
    height: 120,
  },
  inputBox: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
    fontSize: '18px'
  },
  loginButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f9c730',
    width: '108px',
    display: 'inline-block',
    padding: 10,
    borderRadius: 50,
  },
  loginButtonText: {
    color: '#193057',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '700',
    fontSize: '18px'
  },
  loginWrap: {
    padding: '40px',
    backgroundColor: '#193057',
    color: '#fff',
  },
  loginLink: {
    fontWeight: '600',
    color: '#000',
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 40,
    display: 'flex',
  },
  registerText: {
    fontSize: 18,
  },
  registerTextLink: {
    fontSize: 12,
    color: 'white',
    textDecorationLine: 'underline',
  },
  mt4: {
    marginTop: 16,
  },
  mt5: {
    marginTop: 22,
  },
});
