import React, { createContext, useState } from 'react';
// import * as SecureStore from 'expo-secure-store';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axiosConfig from '../helpers/axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        error,
        isLoading,
        login: (email) => {
          setIsLoading(true);
          axiosConfig
            // .post('/login', {
            //   email,
            //   password,
            //   device_name: 'mobile',
            // })
            .post('/login', {
                "username": email,
                "slug1": "",
                "slug2": ""
            })
            .then(response => {
                if (response.data.error == '') {
                    const userResponse = {
                        token: response.data.client_information.title,
                        id: response.data.client_information.id,
                        name: response.data.client_information.title,
                        username: response.data.client_information.title,
                        email: response.data.client_information.email,
                        avatar: response.data.client_information.logo,
                        client_information: response.data.client_information,
                        temp_navigation: response.data.temp_navigation,
                        temp_content: response.data.temp_content,
                        error: response.data.error,
                    };
                    setUser(userResponse);
                    setError(null);
                    // SecureStore.setItemAsync('user', JSON.stringify(userResponse));
                    AsyncStorage.setItem('user', JSON.stringify(userResponse));
                    setIsLoading(false);

                } else {
                    console.log(response);
                    console.log(response.data.error);
                    setError(response.data.error);
                    setIsLoading(false);
                }
            })
            .catch(error => {
              console.log(error.response);
              const key = Object.keys(error.response.data.errors)[0];
              setError(error.response.data.errors[key][0]);
              setIsLoading(false);
            });
        },
        logout: () => {
          // setIsLoading(true);
          axiosConfig.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.token}`;
          axiosConfig
            .post('/logout')
            .then(response => {
              setUser(null);
              console.log('logout - success');

              // SecureStore.deleteItemAsync('user');
                AsyncStorage.removeItem('user');
              setError(null);
              setIsLoading(false);
            })
            .catch(error => {
                console.log('logout - error');
              console.log(error);
              setUser(null);
              // SecureStore.deleteItemAsync('user');
                AsyncStorage.removeItem('user');
              setError(error.response.data.message);
              setIsLoading(false);
            });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
